<template>
  <div id='chapter'>
    <el-card>
      <el-row :gutter="20" class="operate">
        <el-col :span="4">
          <el-select v-model="selectBook" placeholder="请选择书籍" filterable clearable>
            <el-option v-for="item in bookList" :key="item.id" :label="item.book_name" :value="item.book_name"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input v-model="selectName" placeholder="请输入搜索的书籍" clearable></el-input>
        </el-col>
        <el-button @click="search" icon="el-icon-search" type="primary"></el-button>
        <el-button @click="showDialog = true, isAddFlag = true" type="primary">添加章节</el-button>
      </el-row>

      <el-table v-loading="loading" :data="tableData" style="width: 100%" stripe border>
        <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
        <el-table-column prop="book_name" label="书籍名称" width="180"></el-table-column>
        <el-table-column prop="title" label="标题" width="300"></el-table-column>
        <el-table-column prop="content" label="内容" class-name="operate_content"></el-table-column>
        <el-table-column fixed="right" label="操作" width="210">
          <template #default="scope">
            <el-button @click="queryItem(scope.row.id)" type="primary" size="mini">查看</el-button>
            <el-button @click="openDialog(scope.row)" type="warning" size="mini">修改</el-button>
            <el-button @click="deleteItem(scope.row.id)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>

      <!-- 添加弹窗 -->
      <el-dialog @close="closeDialog(isAddFlag, 'addChapterItem')" title="添加章节" :visible.sync="showDialog" class="add_dialog">
        <el-form :model="form" ref="addChapterItem" :rules="rules">
          <el-form-item label="标题" prop="title" :label-width="formLabelWidth">
            <el-input v-model="form.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="书籍名称" prop="book_id" :label-width="formLabelWidth">
            <el-select v-model="form.book_id" ref="bookName" placeholder="请选择书籍" filterable clearable>
              <el-option v-for="item in bookList" :key="item.id" :label="item.book_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="内容" prop="content" :label-width="formLabelWidth">
            <Editor v-model="form.content" :is-clear="false"></Editor>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog(isAddFlag, 'addChapterItem')">取 消</el-button>
          <el-button v-if="isAddFlag" @click="addChapter('addChapterItem')" type="primary">确 定</el-button>
          <el-button v-else @click="editItem('addChapterItem')" type="primary">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 查看弹窗 -->
      <el-dialog title="详情" :visible.sync="checkItem" center class="check_dialog">
        <div class="check_div">
          <div class="check_label">书籍名称：</div>
          <div class="check_data">{{checkData.book_name}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">章节标题：</div>
          <div class="check_data">{{checkData.title}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">章节内容：</div>
          <div v-html="checkData.content" class="check_data"></div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="checkItem = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {getChapterList, addChapterItem, getBookAll, queryChapterItem, editChapterItem, deleteChapterItem} from '@api';
import Editor from "../../components/Editor";

export default {
    components: {Editor},
    data() {
    return {
      params: { // 请求分页参数
        page: 1,
        page_size: 10
      },
      tableData: [], // 表格数据
      loading: true, // 表格加载等待
      showDialog: false, // 是否显示弹窗
      isAddFlag: false, // 是否点击添加用户
      form: { // 添加表单
        book_id: '',
        book_name: '',
        title: '',
        content: '',
        status: 0,
      },
      rules: {
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'}
        ],
        book_id: [
          {required: true, message: '请选择书籍', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请选择内容', trigger: 'blur'}
        ]
      },
      formLabelWidth: '120px', // from表单lanel的宽度
      total: 0, // 数据总条数
      checkItem: false, // 查看每条的弹窗
      checkData: {}, // 查询每条时的数据
      selectName: '', // 搜索姓名
      selectBook: '', // 搜索书籍
      srcList: [''], // 查看图片
      bookList: [], // 书籍列表
    }
  },
  created() {
    this.getData(this.params);
    getBookAll().then(res => this.bookList = res.data);
  },
  methods:{
    selectList() { // 搜索
      const {selectName, selectBook} = this;
      this.params.title = selectName;
      this.params.book_name = selectBook;
      this.getData(this.params);
    },
    addChapter(refName) { // 添加
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        const {book_id} = this.form;
        const body = this.form;
        body.book_id = Number(book_id);
        body.book_name = this.$refs.bookName.selectedLabel;
        addChapterItem(body).then(() => {
          this.showDialog = false;
          this.getData(this.params);
          this.$message.success('添加成功!');
          this.initParams();
        });
      });
    },
    queryItem(id) { // 查询单条数据
      this.checkItem = true;
      this.checkData = {};
      queryChapterItem(id).then(res => this.checkData = res.data);
    },
    editItem(refName) { // 修改单条数据
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        const {form, form: {id}} = this;
        const body = form;
        body.book_name = this.$refs.bookName.selectedLabel;
        const fields = Object.keys(body);
        const fields_1 = this.$removeItem(fields, 'create_time');
        const fields_2 = this.$removeItem(fields_1, 'update_time');
        body.fields = fields_2;
        editChapterItem(id, body).then(() => {
          this.showDialog = false;
          this.$message.success('修改成功！');
          this.getData(this.params);
        });
      });
    },
    deleteItem(id) { // 删除单挑数据
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteChapterItem(id).then(() => {
          this.$message.success('删除成功!');
          this.getData(this.params);
        });
      }).catch(() => this.$message.info('已取消删除'));
    },
    openDialog(item) { // 打开弹窗
      this.form = item;
      this.showDialog = true;
      this.isAddFlag = false;
    },
    closeDialog(flag, refName) { // 关闭弹窗
      if(flag) return this.showDialog = false;
      this.$refs[refName].resetFields();
      this.showDialog = false;
      this.initParams();
      // this.getData(this.params);
    },
    getSrcList(src) { // 查看图片
      this.srcList = [];
      this.srcList.push(src);
    },
    handleCurrentChange(val) { // 获取页数
      this.params.page = val;
      this.getData(this.params);
    },
    handleSizeChange(val) { // 获取每页条数
      this.params.page_size = val;
      this.getData(this.params);
    },
    getData(params) {
      this.loading = true;
      // const {page_size} = this.params;
      getChapterList(params).then(res => {
        this.loading = false;
        const {data, count} = res.data;
        this.total = count;
        this.tableData = data;

        /*if(count > 0 && data.length == 0) {
          const page = count / page_size;
          this.params.page = page;
          getChapterList(this.params).then(result => this.tableData = result.data.data);
        }else {
          this.tableData = data;
        }*/
      })
    },
    initParams() { // 初始化数据
      this.form = {
        book_id: '',
        book_name: '',
        title: '',
        content: '',
        status: 0,
      };
    }
  },
}
</script>

<style lang='less' scoped>

</style>